@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.menu{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
  }
  .logo{
    width: 70px ;
    object-fit: cover;
  }
.menu-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 75px 20px 75px;
}
  .info{
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: black;
    cursor: pointer;
    padding: 0;
    font: inherit;
    font-weight: 700;
    outline: none;
    display: flex;
    gap:6px;
  }
  .info img{
    transform: rotate(30deg);
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
  .container-1{
    margin-top: 300px;
    padding: 0 75px;
  }
  .B2B{
    font-weight: 600;
    font-size: 60px;
    position: relative;
  }
  .additionally-info{
    position: absolute;
    margin-top: -50px;
    margin-left: 250px;
    font-size: 20px;
    font-weight: 250px;
  }
  .more{
    padding-top: 100px;
  }
  .me{
    font-weight: 900;
    font-size: 15px;
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: pointer;
  }
  .more-ipnur{
    margin-top: 20px;
    font-size: 17px;
    transition: max-height 0.5s ease-out, opacity 0.2s ease-out;
    max-height: 500px;
  }

  .more-ipnur.hidden{
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }
  .menu-2{
    display: flex;
    padding: 0px 75px;
    margin-top: 90px;
    align-items: center;
    background-color: #1b6ae3;
  }

  .industry{
    color: white;
    font-size: 30px;
    width: 25%;
    font-weight: 800;
  }
 
  .hover-img {
    display: grid;
    width: 75%;
    grid-template-columns: repeat(5, 1fr);
  }

  .img-industry {
    padding: 50px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;
    cursor: pointer;
    position: relative;
  }

  .industry-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
  }

  .img-industry:hover {
    background-color: rgb(218, 106, 27);
  }

  .img-industry:hover .industry-text {
    visibility: visible;
  }

  .img-industry:hover img {
    opacity: 0;
  }

  .img-industry:hover::content {
    opacity: 100%;
  }

  .img-industry img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }

  .industry-2 {
    font-size: 30px;
    color: black;
    margin: 30px 0;
    font-weight: 800;
    width: 25%;
  }

  .info-industry-img {
    background-image: url("../../assets/coveyor.png");
    width: 100%;
    height: 500px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    background-position: center;
    cursor: pointer;
  }


.container-2{
  padding: 30px 75px 0px 75px;
  display: flex;
  flex-direction: column;
}

.img-con2{
  font-size: 30px;
  font-weight: 700;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  max-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.con2-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  padding-bottom: 100px;
}

.img-con2:nth-child(1){
  background-image: url("../../assets/truck.jpg");
  
}

.img-con2:nth-child(2){
  background-image: url("../../assets/Agriculture.jpg");
  
}
.img-con2:nth-child(3){
  background-image: url("../../assets/woman.png");
  
}
.img-con2:nth-child(4) {
  background-image: url("../../assets/ecoworld.png");
  background-position: center;
  
}
.container-3 {
  background-color: #1b6ae3;
  padding: 150px 70px;
}
.optimal {
color: white;
font-size: 20px;
font-weight: 400;
padding: 80px 20px 80px 0 ;
}
.block{
  color: white;
  font-size: 20px;
  font-weight: 900;
}
.block div{
  margin-bottom: 25px;
}
.block img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.grid{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap:50px;
}
.container-4 {
  padding: 50px 70px;
}

.border {
border: rgb(219, 219, 219) 2px solid;
padding: 15px;
padding-top: 20px;
width: 25%;
}

.box {
  border: 5px solid rgb(255, 0, 0);
  width: 336px; 
  height: 159px;
  border: 2px;
}

.string {
  display: flex;
  padding: 20px 0;
  gap: 10px;
  color: grey;
  font-weight: 500;
}

.string img {
  width:25px ;
  height: 25px;
}
.container-5 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 0 75px;
  
}
.employee{
  height: 296px;
  width: 212px;
  font-size: 15px;
  font-weight: 400;
  padding-top: 50px;
  
}
.employee img {
  height: 217px;
  width: 214px;
}
.employee :nth-child(3) {
  font-size: 17px;
  font-weight: 700;
  padding-top: 10px;
}

.footer {
  background-color: #24272a;
  color: white;
  padding: 47px 75px 47px 73px;
  display: flex;
}

.left {
width: 50%;
}

.call_me{
  font-size: 45px;
  font-weight: 900;
}

.contacts {
  display: flex;
  gap: 25px;
  font-size: 20px;
  font-weight: 900;
  margin-top: 47px;
}

.contacts a {
    text-decoration: none;
    color: white;
}

.contacts img {
  width:20px ;
  height:20px ;
}

.whatsapp {
  margin: 30px 0;
}

.place {
  font-size: 20px;
}
.place :nth-child(1) {
  font-weight: 900;
  font-size: 20px;
}
.right {
  width: 50%;
  gap: 150px;
}
.right :nth-child(1) {
  display: flex;
  flex-direction: column;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  padding: 70px;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  width: calc(100vw - 140px);
  z-index: 100000;
  animation: slideIn 0.5s forwards;
}

.popup-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}

.popup-title {
  font-size: 36px;
  font-weight: 600;
}

.popup-close {
  width: 50px;
  object-fit: cover;
  cursor: pointer;
}

.popup-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  width: 100%;
  align-items: center;
}

.popup-grid img {
  object-fit: cover;
  max-width: 200px;
  max-height: 160px;
}

@media (max-width: 900px) {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100vh;
    width: calc(100vw - 40px);
    z-index: 100000;
  }

  .popup-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  .popup-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 100%;
    align-items: center;
  }
  
  .popup-grid img {
    object-fit: cover;
    max-width: calc(40vw - 20px);
    max-height: 100px;
  }


    .menu-info {
        padding: 20px;
    }

    .B2B{
        font-size: 40px;
        position: relative;
      }

    .container-1{
        padding: 0 10px;
        margin-top: 150px;
    }

    .additionally-info{
        position: inherit;
        margin-top: 50px;
        margin-left: inherit;
        font-size: 20px;
        font-weight: 250px;
      }

    .menu-2{
        display: flex;
        flex-direction: column;
        padding: 50px 10px 50px 10px;
        gap: 20px
    }

    .industry{
        color: white;
        font-size: 30px;
        width: 100%;
        font-weight: 800;
        text-align: center;
      }

    .hover-img {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }

    .container-2{
        padding: 30px 0 0 0;
        display: flex;
        flex-direction: column;
    }

    .info-industry-img {
      background-image: url("../../assets/coveyor.png");
        width: 100%;
        height: 500px;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        text-align: center;
        background-position: center;
    }

    .con2-info {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
        padding-bottom: 0px;
    }

    .industry-2 {
        text-align: center;
        width: 100%;
    }

    .container-3 {
        background-color: #1b6ae3;
        padding: 100px 10px;
    }

    .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .footer {
        background-color: #24272a;
        color: white;
        padding: 47px 10px 47px 10px;
        display: flex;
        flex-direction: column;
    }

    .left, .right {
        width: 100%;
    }

    .left {
        margin-bottom: 20px;
    }
}